<template>
	<tr class="dynamic_address_location_container">
		<td>
			<strong v-html="rendAddressTitle" />
		</td>
		<td class="text-center">
			<b-form-checkbox :id="makeItemRef('dynamic_adrress_same_lcoation')" v-model="isSameAddress" name="dynamic_adrress_same_lcoation" size="lg" />
		</td>
		<td>
			<div v-if="checkAddress" class="title_address">
				<span v-html="rendFilledAddress" />
			</div>
		</td>
		<td>
			<div v-if="!isSameAddress" class="text-center">
				<b-button size="sm" class="btn bg-transparent border-0" @click="openAdresseModal">
					<component :is="getLucideIcon('Edit')" color="#225CBD" :size="20" v-if="checkAddress" />
					<component :is="getLucideIcon('PlusCircle')" color="#225CBD" :size="20" v-else />
				</b-button>
				<b-button size="sm" v-if="checkAddress" class="btn bg-transparent border-0 ml-1" @click="handleDeleteAddress">
					<component :is="getLucideIcon('Trash2')" color="#EA4E2C" :size="20" />
				</b-button>
			</div>
		</td>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="rendAddressTitle"
			ref="modal"
			size="xl"
			v-model="isAddressModalOpen"
			@ok="handleAddressSubmit"
			@cancel="handleAdressModalCancel"
			@hidden="handleAdressModalCancel"
			ok-variant="success"
			ok-title="Save"
			cancel-title="Cancel"
			modal-class="mui-animation"
			:fade="false"
		>
			<AddressForm :edit-data="editAddressValue" @change="handleAdressChange" />
		</b-modal>
	</tr>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import addressHelperMixin from '@/components/LocationService/addressHelper.mixin';
import dynamicAddressItemMixin from '@/components/LocationService/dynamicAddressItem.mixin';
import * as icons from 'lucide-vue';

export default {
	name: 'DynamicAddressComponent',
	mixins: [globalMixin, addressHelperMixin, languageMessages, dynamicAddressItemMixin],
	methods: {
		getLucideIcon(name) {
			return icons[name];
		}
	}
};
</script>

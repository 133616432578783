<template>
	<div class="location-details-item-mobile">
		<div class="card">
			<div class="card-body">
				<div class="p-0">
					<h6 class="fw-600" v-html="rendAddressTitle" />
				</div>

				<div class="pt-2 pb-3">
					<div class="title">
						<div v-if="checkAddress" class="title_address">
							<span v-html="rendFilledAddress" />
						</div>
					</div>

					<div class="same-address lh-20">
						<b-form-checkbox :id="makeItemRef('dynamic_adrress_same_lcoation')" v-model="isSameAddress" name="dynamic_adrress_same_lcoation">
							{{ FormMSG(21, 'Same as location address') }}
						</b-form-checkbox>
					</div>
				</div>

				<div class="d-flex justify-content-end">
					<b-row v-if="!isSameAddress">
						<b-col sm="12">
							<b-button variant="success m-0" size="sm" @click="openAdresseModal">
								<span v-html="checkAddress ? FormMSG(2, 'Edit') : FormMSG(1, 'Add')" />
							</b-button>
							<b-button variant="danger" size="sm" @click="handleDeleteAddress" class="ml-2" v-if="checkAddress">
								{{ FormMSG(3, 'Remove') }}
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>

		<b-modal
			header-class="header-class-modal-doc-package"
			:title="rendAddressTitle"
			ref="modal"
			size="xl"
			v-model="isAddressModalOpen"
			@ok="handleAddressSubmit"
			@cancel="handleAdressModalCancel"
			@hidden="handleAdressModalCancel"
			ok-variant="success"
			ok-title="Save"
			cancel-title="Cancel"
			modal-class="mui-animation"
			:fade="false"
		>
			<AddressForm :edit-data="editAddressValue" @change="handleAdressChange" />
		</b-modal>
	</div>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import addressHelperMixin from '@/components/LocationService/addressHelper.mixin';
import dynamicAddressItemMixin from '@/components/LocationService/dynamicAddressItem.mixin';

export default {
	name: 'LocationDetailsItemAddressItemMobileViewComponent',
	mixins: [globalMixin, addressHelperMixin, languageMessages, dynamicAddressItemMixin]
};
</script>

<style lang="scss" scoped>
.action_cotnainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;

	button {
		width: 100%;
		&:first-child {
			margin-right: 20px;
		}
	}
}
.head,
.content {
	width: 100%;
	margin-bottom: 10px;
}
.content {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;

	& > div:first-child {
		margin-right: 20px;
	}

	.title,
	.same-address {
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.content {
		display: block;
		.same-address {
			padding-top: 10px;
		}
	}
}
</style>
